h1 {
    text-align: center;
}

table {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
}

table th,
table td {
  border: 1px solid #ccc;
  padding: 8px;
}

.progress-bar {
    width: 100%;
    background-color: lightgray;
    height: 20px;
    border-radius: 5px;
    box-sizing: border-box;
    overflow: hidden;
}

.progress-bar__fill {
    background-color: darkgray;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1rem;
    transition: width 0.5s ease-in-out;
}

.progress-bar__text {
    font-size: small;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 24px;
  }

  table {
      width: 100%;
      margin: 10px;
      overflow-x:auto;
  }

  table th,
  table td {
    border: 1px solid #ccc;
    padding: 4px;
  }

  .progress-bar {
    width: 100%;
      max-width: 70px;
    background-color: lightgray;
    height: 16px;
    border-radius: 3px;
    box-sizing: border-box;
    overflow: hidden;
  }

  .progress-bar__fill {
    background-color: darkgray;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 0.8rem;
    transition: width 0.5s ease-in-out;
  }

  .progress-bar__text {
    font-size: 8px;
  }
}