body {
  font-family: "Noto", sans-serif;
  margin: 0;
  padding: 0;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.search-bar {
  width: 500px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 16px;
  outline: none;
}

.search-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.results {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.result {
  padding: 10px 15px;
  border-bottom: 1px solid #ccc;
}

.result-title {
  font-size: 18px;
  font-weight: bold;
}

.result-snippet {
  font-size: 16px;
}



@media screen and (max-width: 768px) {
  body {
    font-size: 16px;
  }

  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .search-bar {
    width: 100%;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 30px 15px;
    font-size: 16px;
    outline: none;
  }

  .search-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .results {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .result {
    padding: 10px 15px;
    border-bottom: 1px solid #ccc;
  }

  .result-title {
    font-size: 18px;
    font-weight: bold;
  }

  .result-snippet {
    font-size: 16px;
  }
}
