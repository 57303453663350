.typeahead-container {
    width: 100%;
    max-width: 600px;
    margin: auto;
    position: relative;
}

.typeahead-input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.typeahead-results {
    position: absolute;
    width: 100%;
    border: 1px solid #ccc;
    border-top: none;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    z-index: 100;
    background-color: white;
    list-style: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.typeahead-results li {
    padding: 10px;
    border-top: 1px solid #ccc;
    cursor: pointer;
}

.typeahead-results li:hover,
.typeahead-results li:active {
    background-color: #eee;
}

.typeahead-results li.highlighted {
    background-color: #ddd;
}

.typeahead-results li.selected {
    font-weight: bold;
}

.itemLabel {
    font-size: large;
}

.itemDesc {
    font-size: small;
}