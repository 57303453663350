.header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  background-color: #f8f8f8;
}

.header-logo {
  justify-items: center;
  font-size: 1.5em;
  color: #333;
  font-weight: bold;
}

.header-nav a {
  margin-left: 20px;
  color: #333;
  text-decoration: none;
}

.header-nav a:hover {
  color: #555;
}

@media screen and (max-width: 768px) {
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    background-color: transparent;
  }

  .header-logo {
    font-size: 1em;
    color: #333;
    font-weight: bold;
  }

  .header-nav a {
    margin-left: 0;
    font-size: 0.8em;
    color: #333;
    text-decoration: none;
  }

  .header-nav a:hover {
    color: #555;
  }
}
